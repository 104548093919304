import React from "react"
import styled from "@emotion/styled"
import { theme } from "../layouts/theme"
import { mediaQuery } from "./styled"

const { blueDarker, whiteLighter, yellow, blue, white } = theme.colors

const RecommendedContentContainer = styled.article`
  margin-bottom: 1.5rem;
`
const RecommendedContent = styled.div`
  width: 100%;
  background-color: ${blueDarker};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ContentHeader = styled.p`
  color: ${whiteLighter};
  margin: 1rem 0 0.4rem 1.5rem;
  font-size: 0.7rem;
  line-height: 1;
  ${mediaQuery.mobile} {
    margin: 1rem 0 0.4rem 1.5rem;
  }
`
const ContentAuthor = styled.p`
  color: ${yellow};
  margin: 0 0 1.2rem 1.5rem;
  font-size: 0.5rem;
  line-height: 0;
`
const InnerContentWrapper = styled.div``

const ContentButton = styled.a`
  width: 40%;
  align-self: stretch;
  background-color: ${yellow};
  ${theme.fonts.calloutsAndSubtitles}
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${white};
  text-decoration: none;
  :hover {
    color: ${white};
    background-color: ${blue};
    transition: ${theme.timing.fast};
  }
`

const RecommendedItem = ({ contentTitle, authorName, href }) => {
  console.log("I am the href in RecommendedItem: ", href)
  return (
    <RecommendedContentContainer>
      <RecommendedContent>
        <InnerContentWrapper>
          <ContentHeader>{contentTitle}</ContentHeader>
          <ContentAuthor>{authorName}</ContentAuthor>
        </InnerContentWrapper>
        <ContentButton href={href}>Take Me There</ContentButton>
      </RecommendedContent>
    </RecommendedContentContainer>
  )
}

export default RecommendedItem
