import React from 'react'
import styled from '@emotion/styled'

const YouTubeVideoContainer = styled.div`
  margin: 3rem auto 3rem auto;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
`
const YouTubeVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const YoutubeVideo = ({ url, authorName, contentTitle }) => {
  return (
    <YouTubeVideoContainer>
      <YouTubeVideo
        id="ytplayer"
        type="text/html"
        width="100%"
        height="100%"
        src={url['en-US']}
        frameborder="0"
        allowfullscreen
      />
    </YouTubeVideoContainer>
  )
}

export default YoutubeVideo
