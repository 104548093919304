import React, { Component, createRef, useRef, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql } from "gatsby"
import uuid from "uuid"
import get from "lodash/get"

import { theme } from "../v0/layouts/theme"
import FloatingMenu from "../v0/components/FloatingMenu"
import RecommendedItem from "../v0/components/RecommendedItem"
import Layout from "../v0/layouts/Layout"
import YoutubeVideo from "../v0/components/YoutubeVideo"
import { mediaQuery } from "../v0/components/styled"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)

const FullIFrame = styled.iframe`
  width: 100%;
  border: none;
  height: 650px;
  ${mediaQuery.mobile} {
    height: 350px;
  }
  margin-bottom: 1.5rem;
`

const TitleContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 3rem auto;
  height: 10rem;
  max-width: 80%;
  ${mediaQuery.mobile} {
    width: 95%;
    height: 6rem;
    margin: 0 auto 1.5rem auto;
  }
`
const Title = styled.h1`
  text-align: center;
  margin-bottom: 0;
  ${mediaQuery.mobile} {
    ${theme.fonts.headline1_mobile}
  }
`

const PostContainer = styled.article`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 80%;
  ${mediaQuery.mobile} {
    max-width: 95%;
  }
`
const ContentfulImage = styled.img`
  margin: 1.5rem auto;
  display: block;
  width: 70%;
  ${mediaQuery.mobile} {
    width: 100%;
  }
`

const SingleBlogPost = props => {
  const arr = []
  //StartScrollRefTry
  const refsArray = useRef([])
  //the refs are set later in time than the ids so different counts
  //handle the number of h2s being set
  let hRefCount = 0
  let dynamicRefCount = 0
  //Handles the ID name which we can later match to the dynamic ref name
  const increaseCount = () => {
    let refName = `&${dynamicRefCount}`
    dynamicRefCount++
    return refName
  }

  const getParentRef = (e, measureMenu) => {
    e.preventDefault()
    let useIdToGetRef = parseInt(e.target.id.substring(1))
    let ref = refsArray.current[useIdToGetRef]
    let menuHeight = measureMenu.current.offsetHeight - 32
    gsap.to(window, {
      duration: 0.8,
      scrollTo: { y: ref, offsetY: menuHeight }
    })
  }

  const ifNumberAddAnchorId = (node, children) => {
    let anchorId
    //TODO: Find a better way to handle  Super
    if (children.toString().slice(0, 1) === "0") {
      anchorId = children.toString().slice(0, 2)
    } else {
      anchorId = uuid.v4()
    }
    const header = node.nodeType
    switch (header) {
      case "heading-1":
        return <h1>{children}</h1>
        break
      case "heading-2":
        return (
          <h2
            id={increaseCount()}
            ref={ref => {
              hRefCount++
              refsArray.current[hRefCount] = ref
            }}
          >
            {children}
          </h2>
        )
        break
      case "heading-3":
        return <h3 id={anchorId}>{children}</h3>
        break
      case "heading-4":
        return <h4 id={anchorId}>{children}</h4>
        break
      case "heading-5":
        return <h5 id={anchorId}>{children}</h5>
        break
      case "heading-6":
        return <h6 id={anchorId}>{children}</h6>
        break
      default:
        throw new Error("Not an h-tag")
    }
  }

  const accordionMenuClickHandler = e => {
    const drawer = document.getElementById("drawer")
    if (drawer.style.maxHeight) {
      drawer.style.maxHeight = null
      drawer.style.transition = `max-height 1s ease-out`
    } else {
      drawer.style.transition = `max-height 1s ease-in`
      drawer.style.maxHeight = "100vh"
    }
  }
  const post = get(props, "data.contentfulBlog")
  const parsedPost = post.body.json
  let options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
      [MARKS.CODE]: text => {
        arr.push(text)
        return
      }
    },
    renderNode: {
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol type="1">{children}</ol>,
      [BLOCKS.HR]: node => <hr></hr>,
      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
      [BLOCKS.HEADING_1]: (node, children) =>
        ifNumberAddAnchorId(node, children),
      [BLOCKS.HEADING_2]: (node, children) =>
        ifNumberAddAnchorId(node, children),
      [BLOCKS.HEADING_3]: (node, children) =>
        ifNumberAddAnchorId(node, children),
      [BLOCKS.HEADING_4]: (node, children) =>
        ifNumberAddAnchorId(node, children),
      [BLOCKS.HEADING_5]: (node, children) =>
        ifNumberAddAnchorId(node, children),
      [BLOCKS.HEADING_6]: (node, children) =>
        ifNumberAddAnchorId(node, children),
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        //Check to see if the embedded entry is for the 'stackblitzIframeLink' which gets set in Contenful
        const embeddedEntryID = node.data.target.sys.contentType.sys.id
        if (embeddedEntryID === "stackblitzIframeLink") {
          return (
            <FullIFrame src={node.data.target.fields.url["en-US"]}></FullIFrame>
          )
        } else if (embeddedEntryID === "blogShortCutLinks") {
          const linksArray = node.data.target.fields.menuLinks["en-US"]
          return (
            <FloatingMenu
              clickHandler={accordionMenuClickHandler}
              linksArray={linksArray}
              options={options}
              getParentRef={getParentRef}
            />
          )
        } else if (embeddedEntryID === "recommendedContent") {
          const {
            contentTitle,
            url,
            isVideo,
            authorName
          } = node.data.target.fields
          if (isVideo["en-US"]) {
            return (
              <YoutubeVideo
                url={url}
                authorName={authorName}
                contentTitle={contentTitle}
              />
            )
          } else {
            return (
              <RecommendedItem
                href={url["en-US"]}
                authorName={authorName["en-US"]}
                contentTitle={contentTitle["en-US"]}
              />
            )
          }
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { title, description, file } = node.data.target.fields
        const mimeType = file["en-US"].contentType
        const mimeGroup = mimeType.split("/")[0]

        switch (mimeGroup) {
          case "image":
            return (
              <ContentfulImage
                title={title ? title["en-US"] : null}
                alt={description ? description["en-US"] : null}
                src={file["en-US"].url}
              />
            )
          case "application":
            return (
              <a
                alt={description ? description["en-US"] : null}
                href={file["en-US"].url}
              >
                {title ? title["en-US"] : file["en-US"].details.fileName}
              </a>
            )
          default:
            return (
              <span style={{ backgroundColor: "red", color: "white" }}>
                {" "}
                {mimeType} embedded asset{" "}
              </span>
            )
        }
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => <a>{children}</a>,
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={`${node.data.uri}`}>{children}</a>
      )
    }
  }
  return (
    <Layout>
      <TitleContainer>
        <Title>{post.title}</Title>
      </TitleContainer>
      <PostContainer>
        <div>{documentToReactComponents(parsedPost, options)}</div>
      </PostContainer>
    </Layout>
  )
}

export default SingleBlogPost

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      slug
      title
      body {
        json
      }
    }
  }
`
