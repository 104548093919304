import React, { useState, useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import HamburgerReworked from "./HamburgerReworked"

import { INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { theme } from "../layouts/theme"
import { mediaQuery } from "./styled"

const SecondaryMenuWrapper = styled.nav`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0px;
  z-index: 100;
`

const SecondaryMenu = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${theme.colors.yellow};
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
  ${mediaQuery.mobile} {
    height: 40px;
    margin: 0rem auto 0rem auto;
  }
`
const ProgressMenu = styled.div`
  background-color: ${theme.colors.red};
  position: absolute;
  bottom: 0;
  z-index: 1000;
  ${mediaQuery.mobile} {
    height: 2px;
  }
`

const SecondaryMenuDrawer = styled.div`
  background-color: ${theme.colors.white};
  width: 100%;
  max-height: ${({ isOpen, drawerHeight }) => (isOpen ? drawerHeight : 0)};
  transition: max-height 0.15s ease;
  background-color: ${theme.colors.white};
  border: none;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 999;

  h2 {
    margin: 0;
  }
  p {
    margin-bottom: 0;

    a {
      text-decoration: none;
      font-size: 1rem;
      color: ${theme.colors.blueDarker};
      margin-bottom: 0.5rem;
      text-transform: uppercase;

      :last-of-type {
        margin-bottom: 0;
      }
    }
  }
`
const LinksWrapper = styled.div`
  padding: 1rem;
`
const DrawerMessage = styled.div`
  padding: 0.7rem;
  background-color: ${theme.colors.blueDarker};
  color: ${theme.colors.whiteLighter};
  font-size: 0.6rem;
  p {
    line-height: 1.4;
  }
`
const Anchor = styled.a`
  &::after {
    content: " ";
    display: block;
    height: 1px;
    background: ${theme.colors.blueDarker};
    width: 100%;
    margin-bottom: 0.3rem;
  }
`
const SideButton = styled.a`
  height: 100%;
  width: 10rem;
  background-color: ${theme.colors.blueDarker};
  color: ${theme.colors.white};
  margin-right: 1rem;
  position: absolute;
  right: 2rem;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  ${theme.fonts.calloutsAndSubtitles}

  &:hover {
    background-color: ${theme.colors.greyDarker};
    color: ${theme.colors.white};
  }

  ${mediaQuery.mobile} {
    width: 7.5rem;
    ${theme.fonts.calloutsAndSubtitles_mobile}
    right: .5rem;
  }
`
const HamburgerNotice = styled.p`
  position: absolute;
  left: 3rem;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 0.5rem;
  font-family: Oswald, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  ${mediaQuery.mobile} {
    display: none;
  }
`

const FloatingMenu = ({ linksArray, getParentRef }) => {
  useEffect(() => {
    window.onscroll = () => {
      handleScrollProgress()
    }
  })
  const [isOpen, setIsOpen] = useState(false)
  //Used to calculate the dynamic height of the drawer
  const [height, setHeight] = useState("0px")
  const measureMenu = useRef(0)
  const drawer = useRef(null)
  const scrollRef = useRef(null)
  const progressBarRef = useRef(null)

  const handleScrollProgress = () => {
    let scrollable =
      document.body.scrollTop || document.documentElement.scrollTop
    let height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    let progress = (scrollable / height) * 100
    if (progressBarRef.current) {
      progressBarRef.current.style.width = `${progress}%`
    }
  }

  const openCloseAccordion = () => {
    if (!isOpen) {
      setIsOpen(true)
      setHeight(`${drawer.current.scrollHeight}px`)
    } else {
      setIsOpen(false)
      setHeight("0px")
    }
  }

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <Anchor
          ref={scrollRef}
          id={node.data.uri}
          onClick={event => {
            setIsOpen(false)
            getParentRef(event, measureMenu)
          }}
          href={""}
        >
          {children}
        </Anchor>
      )
    }
  }

  return (
    <SecondaryMenuWrapper ref={measureMenu} id="accordion">
      <SecondaryMenu>
        <div
          css={css`
            margin-left: 1rem;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
          `}
        >
          <HamburgerReworked
            clickHandler={openCloseAccordion}
            isOpen={isOpen}
            css={css`
              z-index: 200;
            `}
          />
        </div>
        <HamburgerNotice>⭠ Table of Contents</HamburgerNotice>
        <SideButton href={`/#community`}>Join DevWell</SideButton>
        <ProgressMenu ref={progressBarRef} />
      </SecondaryMenu>
      <SecondaryMenuDrawer ref={drawer} isOpen={isOpen} drawerHeight={height}>
        <LinksWrapper>
          {documentToReactComponents(linksArray, options)}
        </LinksWrapper>
        <DrawerMessage>
          <p>
            We're developers giving back. Join us on Slack to get mentorships,
            live-sessions or workshops at no cost — no strings attached. Want to
            volunteer? We can use your support training students, creating
            tutorials, and writing blogs.
          </p>
        </DrawerMessage>
      </SecondaryMenuDrawer>
    </SecondaryMenuWrapper>
  )
}

export default FloatingMenu
